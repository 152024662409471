<template>
  <v-sheet>
    <v-card 
      v-for="sport in sports"
      :key="sport.id + 'sport'"
      class="my-5 mx-5"
      @click="$router.push(`/tiimi-admin/sports/${sport.id}/tags`)"
    >
      <v-card-title>
        {{sport.sport_name}}
      </v-card-title>
    </v-card>
    <v-card flat>
      <router-view></router-view>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  created() {
    this.initSports()
  },
  methods: {
    ...mapActions('admin', ['initSports'])
  },
  computed: {
    ...mapGetters('admin', ['sports'])
  }
}
</script>